@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

// Main Font Family
$font-family-sans-serif: 'Montserrat',
"Helvetica Neue",
Helvetica,
Arial,
sans-serif !default;

$font-family-button: 'PT Serif',Georgia,"Times New Roman",Times,serif;
$font-family-glyphicon: 'Glyphicons Halflings';

// Aux Colors
$aux-white: #fff;
$aux-black: #000;
$aux-grey100: #f8f9fa;
$aux-grey200: #eee;
$aux-grey300: #cacaca;
$aux-grey400: #d8d8d8;
$aux-grey500: #bfc8d6;
$aux-grey600: #979797;
$aux-grey700: #6b7074;
$aux-grey900: #3a3d3f;
$aux-greyBlue: #8193ae;
$aux-yellow: #ffd442;
$aux-chinaIvory: #FFF6D5;
$aux-grey-background: #F7F7F7;
$aux-dark-grey-background: #E6E6E6;
$aux-grey-title-oauth2: #434c5f;
$aux-grey-subtitle-oauth2: #596377;
$aux-grey-background-oauth2: #ededed;
$aux-grey-color-oauth2: #5f697a;
$aux-grey-background-pdcourses-tabs: #f3f1f2;
$aux-grey-color-pdcourses-card: #333;


$black4: #f5f5f5;
$black9: #e6e6e6;
$black11: #e3e3e3;
$black17: #d4d4d4;
$black20: #ccc;
$black32: #ababab;
$black33: #aaa;
$black50: #7e7e7e;
$black58: #666962;
$black60: #646464;
$black61: #616364;
$black71: #4a4a4a;
$black74: #3c3d41;
$black96: #090a0a;

$cyan75: #3ec3fd;
$blue71C3E6: #71c3e6;
$blue68BFE4: #68bfe4;
$blue20BAFD: #20bafd;
$blue15B7FD: #15b7fd;
$blue01A8EF: #01a8ef;
$blue01A1E5: #01a1e5;
$blue0394D3: #0394d3;
$blue0271A0: #0271a0;
$blue23527C: #23527c;
$blue014D6E: #014d6e;
$student-link-background: #022147;
$student-link-border: #74859a;
$blue-promotion-title-background: #0182b9;
$blue-pdcourses-tabs-background: #05409a;
$blue-pdcourses-tabs-border: #639cf5;

$green7ED321: #7ed321;

$redF45531: #f45531;
$red: #f00;
$red-error-form: #dc3545;

$magenta47: #c16ed2;
$orangeF5A623: #f5a623;
$orange-promotion-sticker: #fe7e14;
$orange-ghp-nonstock: #f5e4d0;

$product-viewer-font: #212529;
$product-viewer-title: #002147;
$product-viewer-background: #f2f5f7;
$product-viewer-box-shadow: #8cabd1;
$product-viewer-link: #2c64aa;



// Variables
$dark-blue: #002147;
$clear-blue: #0294d3;
$clear-green: #85c939;
$info: #fd7e14;
// $clear-blue-hover: #3ec3fd;
$oxford-blue:#011e41;


$theme-colors: (
  "primary": $clear-blue,
  "secondary": $dark-blue,
  "success": $clear-green,
  "info": $info 
);

// Navbar Overwrite
$navbar-padding-y: (1rem / 0.85) !default;
$navbar-padding-x: 1.5rem !default;
$navbar-nav-link-padding-x: 1.3rem !default;

// Dropdown Overwrite
$dropdown-padding-y: .1rem !default;
$dropdown-bg: $aux-white !default;
$dropdown-divider-bg: $aux-grey300 !default;
$dropdown-border-color: $aux-grey100 !default;

// Glyphicons config
$bootstrap-sass-asset-helper: false;
$icon-font-name: 'glyphicons-halflings-regular';
$icon-font-svg-id: 'glyphicons_halflingsregular';
$icon-font-path: '//maxcdn.bootstrapcdn.com/bootstrap/3.3.6/fonts/';

.text-noselect {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.cursor-pointer { cursor:pointer; }

@include media-breakpoint-up(sm) {
  .w-sm-25 { width: 25% !important; }
  .w-sm-50 { width: 50% !important; }
  .w-sm-75 { width: 75% !important; }
  .w-sm-100 { width: 100% !important; }
  .w-sm-auto { width: auto !important; }
  .h-sm-25 { height: 25% !important; }
  .h-sm-50 { height: 50% !important; }
  .h-sm-75 { height: 75% !important; }
  .h-sm-100 { height: 100% !important; }
  .h-sm-auto { height: auto !important; }
}
@include media-breakpoint-up(md) {
  .w-md-25 { width: 25% !important; }
  .w-md-50 { width: 50% !important; }
  .w-md-75 { width: 75% !important; }
  .w-md-100 { width: 100% !important; }
  .w-md-auto { width: auto !important; }
  .h-md-25 { height: 25% !important; }
  .h-md-50 { height: 50% !important; }
  .h-md-75 { height: 75% !important; }
  .h-md-100 { height: 100% !important; }
  .h-md-auto { height: auto !important; }
}
@include media-breakpoint-up(lg) {
  .w-lg-25 { width: 25% !important; }
  .w-lg-50 { width: 50% !important; }
  .w-lg-75 { width: 75% !important; }
  .w-lg-100 { width: 100% !important; }
  .w-lg-auto { width: auto !important; }
  .h-lg-25 { height: 25% !important; }
  .h-lg-50 { height: 50% !important; }
  .h-lg-75 { height: 75% !important; }
  .h-lg-100 { height: 100% !important; }
  .h-lg-auto { height: auto !important; }
}
@include media-breakpoint-up(xl) {
  .w-xl-25 { width: 25% !important; }
  .w-xl-50 { width: 50% !important; }
  .w-xl-75 { width: 75% !important; }
  .w-xl-100 { width: 100% !important; }
  .w-xl-auto { width: auto !important; }
  .h-xl-25 { height: 25% !important; }
  .h-xl-50 { height: 50% !important; }
  .h-xl-75 { height: 75% !important; }
  .h-xl-100 { height: 100% !important; }
  .h-xl-auto { height: auto !important; }
}
