.headerOUP {
  /*background-color: $dark-blue !important;*/
  background-color: $oxford-blue !important; /* MBQ APR 2025 NEW LOGO */
  font-size: 0.9rem;
  
  .navbar-brand {
    display: contents !important;
    img {
      width: 65% !important;
    }
  }

  /*& li.nav-item {
    width: max-content;
  }*/

  a:not([href]):not([tabindex]) {
    color: $aux-greyBlue;
    transition: 0.4s;
  }

  a:not([href]):not([tabindex]):hover {
    color: $aux-grey500;
  }

  a {
    color: $aux-greyBlue;
    transition: 0.4s;
  }

  a:hover {
    color: $aux-grey500;
  }

  .btn-transparent {
    color: $aux-white;
    border-color: $aux-white;
    border-radius: 50px;
    border: 1px solid;
    min-width: 150px;
    text-align: center;
  }

  .btn-transparent:hover,
  .btn-transparent:focus,
  .btn-transparent.focus,
  .btn-transparent:active,
  .btn-transparent.active {
    color: $aux-white;    
    background-color: rgba(0, 0, 0, 0);
    border-color: $aux-grey300;
  }

  &__dropdown {
    box-shadow: 0 .5rem 1rem rgba($black, .175);
    top: 2.75rem;

    >span {
      display: none;
    }

    &__item {
      cursor: pointer;
      font-size: 0.8rem;
      transition: 0.4s;

      a {
        padding: 0 !important;

        span:first-of-type {
          margin-right: 0.5rem;
        }
      }

      &:hover {
        background: $white;
        opacity: 0.65;
      }

      padding: 0.6rem 1rem;
    }

    &__toggle {
      .user-impersonated {
        color: $aux-yellow;
      }
      .glyphicon {
        margin-right: .4rem;
      }
    }
  }

  &__divider {
    margin: 0;
  }

  &__toggler {
    border: solid 1px $aux-white;
    background: str-replace(url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='#{$aux-white}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"), "#", "%23");
    background-position: center center;
    background-repeat: no-repeat;
  }
}

.responsable_modal {
  & .modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    display: none;
    overflow: hidden;
  }

  & .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    /*width: 480px;*/
    pointer-events: auto;
    background-color: $aux-white;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    float: left;
    font-family: $font-family-sans-serif;
    font-size: 13px;
    box-shadow: 0 5px 15px rgba(0,0,0,.5);
  }

  & .modal-header {
    border-bottom: 0;
  }

  & .modal__header button.close {
    color: $aux-grey900;
    filter: alpha(opacity=80);
    border: 1px solid $aux-grey600;
    border-radius: 35px;
    opacity: .8;
    cursor: pointer;
    background: transparent;
    padding: 6px 9px;
    margin-top: -5px;
    margin-right: -5px;
    font-size: 13px;
  }

  & .modal__header button.close:hover, & .modal__header button.close:focus {
    filter: alpha(opacity=50);
    opacity: .5;
  }

  & .modal__header small, & .modal__header .small {
    font-size: 15px;
    font-weight: 400;
    font-family: $font-family-button;
    color: $aux-grey600;
    padding-left: 10px;
    padding-right: 10px;
    line-height: 1.1;
  }

  & .modal-body {
    float: left;
    margin-top: 0;
    text-align: left;
    /*width: 480px;*/
    padding-top: 0;
    padding-bottom: 40px;
    font-family: $font-family-sans-serif;
    font-size: 13px;
    color: $aux-grey600;
    padding-left: 30px;
    padding-right: 30px;
  }
}

.rainbow {
  margin: 0;
  height: 0.3rem;

  &__red {
    background: #b50837;
  }

  &__pink {
    background: #b7557f;
  }

  &__yellow {
    background: #d5a722;
  }

  &__green {
    background: #8b8e07;
  }

  &__aqua {
    background: #02a2b1;
  }

  &__blue {
    background: #0294b3;
  }
}

/* MBQ PD-COURSES */
/* old */
/*@media (min-width: 576px) {
  .headerOUP .navbar-brand {
    display: content !important;
  }
  .headerOUP .navbar-brand img {
    width: auto !important;
  }
}*/
/*  new */
/* >>MBQ APR 2025 NEW LOGO */
/*
@media (min-width: 576px) {
  .headerOUP .navbar-brand {
    display: content !important;
  }
  .headerOUP .navbar-brand img {
    width: 20% !important;
  }
}
@media (min-width: 992px) {
  .headerOUP .navbar-brand img {
    width: auto !important;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .headerOUP {
    font-size: 0.8rem;
  }
  .headerOUP.navbar {
    padding: 0.5rem 0.5rem;
  }
  .headerOUP .navbar-nav .nav-link {
    padding-right: 0 !important;
    padding-left: 0.625rem !important;/ *10px* /
  }
}
*/
.headerOUP .navbar-brand div.w-brand  {
  max-width: fit-content !important;
}
.headerOUP .navbar-brand img {
  width: 130px !important;
  margin: 0 !important;
  @media (min-width: 992px) {
    width: 150px !important;
  }
}
.headerOUP .navbar-brand div.w-brand.logo {
  padding-left: 5px !important;
  @media (min-width: 1200px) {
    padding-left: 0 !important;
  }
}
.headerOUP .navbar-brand div.w-brand.text {
  font-family: "DM Serif Display";
  color: $aux-white;
  font-weight: 400;
  font-style: normal;
  font-size: 10px;
  letter-spacing: 1px;
  line-height: normal;
  text-align: left;
  padding: 0;
  margin: 0;
  @media (min-width: 992px) {
    font-size: 12px;
  }
}
.headerOUP .navbar-brand div.w-brand.demo img {
  width: 80px !important;
  padding: 5px;
  margin: 0;
}
@media (min-width: 576px) {
  .headerOUP .navbar-brand {
    display: content !important;
  } 
}
@media (min-width: 768px) and (max-width: 992px) {
  .headerOUP {
    font-size: 0.75rem;
  }
  .headerOUP.navbar {
    padding: 0.5rem 0.5rem;
  }
  .headerOUP .navbar-nav .nav-link {
    padding-right: 0 !important;
    padding-left: 0.625rem !important;/*10px*/
  }
}
/* MBQ APR 2025 NEW LOGO<<*/
